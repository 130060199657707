<template>
<nav class="navbar navbar-fixed navbar-expand-sm navbar-dark bg-dark">
  <div class="container-fluid">
    <router-link to="/" class="navbar-brand">W-training</router-link>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarToggler" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarToggler">
      
    <ul class="navbar-nav mr-auto mb-2 mb-lg-0">
      <li class="nav-item"><router-link to="/light" class="nav-link">light</router-link></li>
      <li class="nav-item"><router-link to="/ca" class="nav-link">composition-api</router-link></li>
      <li class="nav-item"><router-link to="/full" class="nav-link">full</router-link></li>
      <!-- <li class="nav-item"><router-link to="/def" class="nav-link">def</router-link></li>
      <li class="nav-item"><router-link to="/about" class="nav-link">About</router-link></li>
      <li class="nav-item"><router-link to="/fb" class="nav-link">Firebase</router-link></li> -->
    </ul>

    </div>
  </div>
</nav>
</template>

<script>

export default {

}
</script>

<style>
#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>