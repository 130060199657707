<template>
  <div class="mt-2">
    <h1 class="text-primary">{{ title }} - (Composition API)</h1>
    <p><b>Cегодня:</b> {{ date }}</p>

    <div v-show="tableVisible">
    <my-table :lessons="lessons"  />
    </div>

    <!-- <button class="btn btn-primary" data-bs-toggle="collapse" data-bs-target="#demo">Изменить тренировку</button> -->

    <button class="btn btn-primary" @click="$router.push({path: '/ca/edit'})">Изменить тренировку</button>

    <div v-if="editVisible">
    <light-editor :lessons="lessons" />
    </div>
  </div>
</template>

<script>
import MyTable from "../../components/light/MyTable.vue"
import LightEditor from "../../components/light/LightEditor.vue"
import {getLightData} from "../../hook/getLightData.js"

export default {
  components: { MyTable, LightEditor },
  data() {
    return {
      title: "Тренировка",
      date: Date().toLocaleString(),
      tableVisible: true,
      editVisible: true,

    };
  },
  setup() {
  // Получение Тренировки с сервера  
  const {lessons, id} = getLightData()

  return { lessons, id }
  },

  methods: {},
  computed: {},
  mounted() {},
};
</script>

<style>

</style>
