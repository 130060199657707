<template>
  <div id="demo" class="collapse">
    {{currentKey}}
    <form @submit.prevent>
      <table class="table table-light table-striped table-hover mt-3">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">x</th>
            <th scope="col">Упражнение</th>
            <th scope="col">Подходы</th>
            <th scope="col">Повторения</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(less, key, index) in lessons.worksArr" :key="index">
            <th scope="row">{{ ++key }}</th>
            <th scope="row">
              <input
                class="form-check-input sm"
                type="checkbox"
                id="checkboxNoLabel"
                :value="--key"
                v-model="currentKey"
                aria-label="..."
              />
            </th>
            <td>
              <input
                type="text"
                class="form-control"
                placeholder="Введите название упрожнения"
                v-model="less.work"
                aria-label="Username"
                aria-describedby="basic-addon1"
              />
            </td>
            <td>
              <div class="input-group mb-3 sm">
                <label class="input-group-text" for="inputGroupSelect02">{{
                  Number(less.sets)
                }}</label>
                <button
                  class="btn btn-outline-secondary btn-sm"
                  type="button"
                  id="minus1"
                  @click="less.sets--"
                  v-bind:disabled="isButtonDisabled"
                >
                  -
                </button>
                <button
                  class="btn btn-outline-secondary btn-sm"
                  type="button"
                  id="plus1"
                  @click="less.sets++"
                  v-bind:disabled="isButtonDisabled"
                >
                  +
                </button>
              </div>
            </td>
            <td>
              <div class="input-group mb-3 sm">
                <label class="input-group-text" for="inputGroupSelect02">{{
                  Number(less.rep)
                }}</label>
                <button
                  class="btn btn-outline-secondary btn-sm"
                  type="button"
                  id="minus1"
                  @click="less.rep--"
                  v-bind:disabled="isButtonDisabled"
                >
                  -
                </button>
                <button
                  class="btn btn-outline-secondary btn-sm"
                  type="button"
                  id="plus1"
                  @click="less.rep++"
                  v-bind:disabled="isButtonDisabled"
                >
                  +
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="input-group mb-3 sm">
        <button class="btn btn-primary" type="button" id="block" @click="block">
          &#128274;
        </button>
        <button
          class="btn btn-secondary"
          type="button"
          id="block"
          @click="addWork"
        >
          добавить
        </button>
        <button
          class="btn btn-danger"
          type="button"
          id="block"
          @click="deleteWork"
        >
          удалить
        </button>
        <button class="btn btn-success" @click="updateData">сохранить</button>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  props: {
    lessons: {
      type: Object,
      require: true,
    },
  },
  data() {
    return {
      currentKey: [],
      isButtonDisabled: true,
    }
  },
  methods: {
    block: function () {
      if (!this.isButtonDisabled) {
        this.isButtonDisabled = true;
      } else {
        this.isButtonDisabled = false;
      }
    },
  }
};
</script>

<style></style>
