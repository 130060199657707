<template>
<transition name="fade">
  <div class="mt-2">
    
    <h1 class="text-primary">{{ title }}</h1>
  

<div class="mt-2 mb-2">
<form @submit.prevent>
      <table class="table table-light table-striped table-hover mt-3">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">x</th>
            <th scope="col">Упражнение</th>
            <th scope="col">Подходы</th>
            <th scope="col">Повторения</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(less, key, index) in lessons.worksArr" :key="index">
            <th scope="row">{{ ++key }}</th>
            <th scope="row">
              <input
                class="form-check-input sm"
                type="checkbox"
                id="checkboxNoLabel"
                :value="--key"
                v-model="currentKey"
                aria-label="..."
              />
            </th>
            <td>
              <input
                type="text"
                class="form-control"
                placeholder="Введите название упрожнения"
                v-model="less.work"
                aria-label="Username"
                aria-describedby="basic-addon1"
              />
            </td>
            <td>
              <div class="input-group mb-3 sm">
                <label class="input-group-text" for="inputGroupSelect02">{{
                  Number(less.sets)
                }}</label>
                <button
                  class="btn btn-outline-secondary btn-sm"
                  type="button"
                  id="minus1"
                  @click="less.sets--"
                  v-bind:disabled="isButtonDisabled"
                >
                  -
                </button>
                <button
                  class="btn btn-outline-secondary btn-sm"
                  type="button"
                  id="plus1"
                  @click="less.sets++"
                  v-bind:disabled="isButtonDisabled"
                >
                  +
                </button>
              </div>
            </td>
            <td>
              <div class="input-group mb-3 sm">
                <label class="input-group-text" for="inputGroupSelect02">{{
                  Number(less.rep)
                }}</label>
                <button
                  class="btn btn-outline-secondary btn-sm"
                  type="button"
                  id="minus1"
                  @click="less.rep--"
                  v-bind:disabled="isButtonDisabled"
                >
                  -
                </button>
                <button
                  class="btn btn-outline-secondary btn-sm"
                  type="button"
                  id="plus1"
                  @click="less.rep++"
                  v-bind:disabled="isButtonDisabled"
                >
                  +
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="input-group mb-3 sm">
        <button class="btn btn-primary" type="button" id="block" @click="block">
          &#128274;
        </button>
        <button
          class="btn btn-info"
          type="button"
          id="block"
          @click="addWork"
        >
          добавить
        </button>
        <button
          class="btn btn-danger"
          type="button"
          id="block"
          @click="deleteWork"
        >
          удалить
        </button>
        <button
          class="btn btn-secondary"
          type="button"
          id="block"
          @click="$router.push({path: '/ca'})"
        >
          отмена
        </button>
        <button class="btn btn-success" @click="updateData">сохранить</button>
      </div>
    </form>
</div>

    <!-- <div class="alert alert-success" role="alert">
  A simple success alert with <a href="#" class="alert-link">an example link</a>. Give it a click if you like.
</div> -->
  </div>
 </transition> 
</template>

<script>
import axios from "axios"

export default {
  data() {
    return {
      title: "Изменить тренировку - (Composition API)",
      id: "",
      lessons: {},
      work: { work: "", sets: null, rep: null },
      currentLesson: 1,
      currentKey: [],
      isButtonDisabled: true,
    };
  },

  methods: {
    block: function () {
      if (!this.isButtonDisabled) {
        this.isButtonDisabled = true;
      } else {
        this.isButtonDisabled = false;
      }
    },

    async getData() {
      const response = await axios.get("http://api.w-app.ru/light");
      this.lessons = response.data[0];
      this.id = this.lessons._id;
    },

    async updateData() {
      const response = await axios.put(
        "http://api.w-app.ru/light/" + this.id,
        this.lessons
      );
      this.getData();
      console.log(response);
      alert("Сохранено!");
      this.$router.push({path: '/ca'})
    },

    addWork() {
      this.lessons.worksArr.push(this.work);
      // console.log(this.lessons.worksArr)
    },

    deleteWork() {
      this.currentKey.forEach(() => {
        this.lessons.worksArr.splice(this.currentKey[0], 1);
      });
      this.currentKey = [];
    },
  },
  computed: {},

  mounted() {
    this.getData();
  },
};
</script>

<style>
@media screen and (max-width: 800px) {
p {font-size: 90%;}
table th{font-size: 70%;}
table td{font-size: 80%;}
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
