<template>
  <table class="table table-light table-striped table-hover mt-1">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Упражнение</th>
            <th scope="col" class="center">Подходы</th>
            <th scope="col" class="center">Повторения</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(less, key, index) in lessons.worksArr" :key="index">
            <th scope="row">{{ ++key }}</th>
            <td>{{less.work}}</td>
            <td class="center">{{less.sets}}</td>
            <td class="center">{{less.rep}}</td>
          </tr>
        </tbody>
      </table>
</template>

<script>
export default {
  props: {
    lessons: {
      type: Object,
      require: true
    }
  } 
}
</script>

<style scope>
.center {text-align: center;}
</style>