<template>
  <div class="d-grid gap-3 mt-3">
    <router-link to="/light" class="btn btn-primary btn-block">LIGHT version</router-link>
    <router-link to="/full" class="btn btn-primary btn-block">FULL version</router-link>
  </div>
</template>

<script>

export default {
  data() {
    return {

    };
  },

  methods: {},
  computed: {},
  mounted() {},
  
};
</script>

<style></style>
