<template>
  <section>
    <navbar />
    <div class="container">
      <router-view />
    </div>
  </section>
</template>

<script>
import Navbar from '@/components/Navbar.vue'

export default {
  components: {Navbar}
}
</script>

<style>

#app {
  font-family: Roboto, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  /* box-sizing: border-box; */
}
</style>
