import { createRouter, createWebHistory } from 'vue-router'
import Main from '@/views/Main.vue'
import Full from '@/views/Full.vue'

import Light from '@/views/light/Light.vue'
import CA from '@/views/light/CA.vue'
import EditLight from '@/views/light/EditLight.vue'

const routes = [
  {
    path: '/',
    name: 'Main',
    component: Main
  },
  {
    path: '/light',
    name: 'Light',
    component: Light
  },
  {
    path: '/ca',
    name: 'CA',
    component: CA
  },
  {
    path: '/ca/edit',
    name: 'EditLight',
    component: EditLight
  },
  {
    path: '/full',
    name: 'Full',
    component: Full
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
