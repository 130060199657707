import axios from 'axios'
import { ref, onMounted } from 'vue'

export function getLightData() {
    const lessons = ref({})
    const id = ref('')
    const getData = async () => {
      try {
      const response = await axios.get("http://api.w-app.ru/light")
      lessons.value = response.data[0]
      id.value = lessons.value._id
          } catch (e) {
        console.error(e);
      } 
    };
  
    onMounted(getData) 
    
    return {
        lessons, id
    }
  }
  