<template>
  <div class="container mt-2">
    <h1>{{ title }} {{lessons.lesson_id}} - "{{nameConfig}}"</h1>
    <p><b>Cегодня:</b> {{Date().toLocaleString()}}</p>    

     <!-- <ul v-for="(less, key, index) in lessons.worksArr" :key="index">
      <li>{{key}}: {{less}}</li>
    </ul>  -->

    <div>
      <button @click="getAllData">getAllData</button>
      <button @click="getCurrentLesson">getCurrentLesson</button>
      <p> lessons: {{lessons}}</p>
      <br><br>

      <button @click="getConfig">getConfig</button>
      <button @click="updateConfig">updateConfig</button>
      <p> Config: {{config}}</p>

    </div>


     <table class="table table-light table-striped table-hover mt-5">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Упражнение</th>
            <th scope="col">Подходы</th>
            <th scope="col">Повторения</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(less, key, index) in lessons.worksArr" :key="index">
            <th scope="row">{{(index)}}</th>
            <td>{{less.work}}</td>
            <td>{{less.sets}}</td>
            <td>{{less.rep}}</td>
          </tr>
        </tbody>
      </table>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data() {
    return {
      title: "Занятие",
      lessons: {},
      currentLesson: 1,
      mongo: [],
      config: [],
      nameConfig: "",
    };
  },

  methods: {

      async getAllData() {
      //  const response = await axios.get('http://localhost:5000/api/lessons') 
       const response = await axios.get('http://api.w-app.ru/api/lessons') 
       this.lessons = response.data
      },

      async getCurrentLesson() {
      //  const response = await axios.get('http://api.w-app.ru/api/lessons/'+this.currentLesson) 
       const response = await axios.get('http://api.w-app.ru/api/lessons/' + this.config.current_lesson) 
       this.lessons = response.data[0]
      //  console.log(response.data)
      },
    
      async getConfig() {
       const response = await axios.get('http://api.w-app.ru/api/config') 
       this.config = response.data[0]
       this.nameConfig = response.data[0].name
      //  console.log(response.data)
      this.getCurrentLesson()
      },

      async updateConfig() {
      const response =  await axios.put('http://api.w-app.ru/api/config/'+this.config._id, 
      { "_id": "6197b3536b867043eea2642e", "current_lesson": 2, "coment": "2 steps", "__v": 0, "name": "Default" }) 
     this.getConfig()
        console.log(response)

      }



  },
  computed: {
 
  },


  
  mounted() {
    this.getConfig()
  },
  
};
</script>

<style></style>
